import * as React from 'react';
import {header, headerTitle, headerContent, headerSubtitle, headerIcon, headerGrid} from './header.module.css';
import IHeader from "../../interfaces/header";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLinkedin, faGithub, faTwitter} from '@fortawesome/free-brands-svg-icons';
import icon from "../../interfaces/icon";
import Profile from "../profile/profile";

const iconList: icon[] = [
    {
        iconName: faLinkedin,
        path: "https://www.linkedin.com/in/cedric-locchi/"
    },
    {
        iconName: faGithub,
        path: "https://github.com/Cedric-locchi"
    },
    {
        iconName: faTwitter,
        path: "https://twitter.com/nice_digit_com"
    }
];

const Header = ({pageTitle, content}: IHeader) => {

    return (
        <header className={header}>
            <section className={headerGrid}>
                <div>
                    <h1 className={headerTitle}>{pageTitle}</h1>
                    <h2 className={headerSubtitle}>software engineer, créateur de contenu & mentor OpenClassroom</h2>
                    <p className={headerIcon}>
                        {
                            iconList.map((icon: icon) => {
                                return (
                                    <a key={icon.path} href={icon.path} target="_blank">
                                        <FontAwesomeIcon icon={icon.iconName} size="2x"/>
                                    </a>
                                )
                            })
                        }
                    </p>
                </div>
                <div>
                    <Profile/>
                </div>
            </section>
            <p className={headerContent}>
                {content}
            </p>
        </header>
    );
}

export default Header