import * as React from 'react'
import {useState} from "react";
import {graphql} from 'gatsby'

import Layout from "../components/layout/layout";
import Card from "../components/cards/card";

import {searchInput, searchGrid, articleNumber, inputLabel} from './blog.module.css';
import Newsletter from "../components/news-letter/newsletter";
import Seo from "../components/seo";

const BlogPage = ({data}: any) => {

    const headerContent = `
        Salut moi c'est cédric, tech lead angular et mentor.
        Ici, on va parler de pleins de choses autour d'angular et de sont écosystème, on vas essayer de vulgariser
        un peut l'utilisation du framework et tenter de démistifier sa complexité.
    `;

    const allPosts = data.allMdx.nodes;
    const [state, setState] = useState({
        filteredData: [],
        query: "",
    })

    const handleInputChange = (event: any) => {
        filterDAta(event.target.value);
    }

    const filterDAta = (query: string) => {

        const filteredData = posts.filter((post: any) => {
            const {excerpt, title, tag} = post.frontmatter

            return (
                excerpt.toLowerCase().includes(query.toLowerCase()) ||
                title.toLowerCase().includes(query.toLowerCase()) ||
                (tag && tag.toLowerCase().includes(query.toLowerCase()))
            );

        });

        setState({query, filteredData});
    }

    const {filteredData, query} = state;
    const hasSearchResults = filteredData && query !== "";
    const posts = hasSearchResults ? filteredData : allPosts;

    return (
        <Layout pageTitle="Cédric Locchi" content={headerContent}>

            <label className={inputLabel} htmlFor="search">Rechercher un article</label>

            <div className={searchGrid}>
                <input
                    type="search"
                    id="search"
                    className={searchInput}
                    onChange={handleInputChange}
                    placeholder="angular, rxjs, etc..."/>
                <span className={articleNumber}>{posts.length}</span>
            </div>

            <hr/>

            {posts.map((node: any, index: number) => <Card key={index} node={node} index={index}/>)}

            <hr/>

            <Newsletter />

        </Layout>
    )

}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "D/MM/YYYY")
          title
          excerpt
          tag
          img {
            name
            publicURL
          }
        }
        id
        slug
        fields {
            readingTime {
              minutes
            }
        }
      }
    }
  }
`

export default BlogPage

export const Head = () => {
    return  <Seo title="cedric locchi | Le blog"
                 slug="/"
                 description="Bienvenue sur mon site, dédié au partage des mes compétences sur le framework angular" />
}