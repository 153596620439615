import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {profileSize} from './profile.module.css';

const Profile = () => {
    const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
        edges {
          node {
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `);

    let sources = ''

    data.images.edges.forEach((image: any) => {
        if (image.node.relativePath === 'profile.png') {
            sources = image.node.publicURL;
        }
    })

    return <img className={profileSize} src={sources} alt=""/>;

};

export default Profile;