import * as React from 'react'
import Header from "../header/header";
import {container} from './layout.module.css';
import ILayout from "../../interfaces/layout";
import Footer from "../footer/footer";
import Navigation from "../navigation/navigation";

const Layout = ({pageTitle, content, children}: ILayout) => {
    return (
        <>
            <Navigation/>
            <div className={container}>
                <Header pageTitle={pageTitle} content={content}></Header>
                <hr/>
                <main>{children}</main>
            </div>
            <Footer/>
        </>
    )
}

export default Layout