import React from "react";
import {articleCard, date, gridTitle, articleTitle, description, chips, readingTime} from "./card.module.css";
import {Link} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMugHot} from "@fortawesome/free-solid-svg-icons";

const Card = ({node}: any)=>{

    return (
        <Link to={`${node.slug}`} key={node.id}>

            <article className={articleCard}>

                <div className={date}>Publié le {node.frontmatter.date}</div>

                <h2 className={gridTitle}>
                    <img src={node.frontmatter.img.publicURL} alt={`logo ${node.frontmatter.img.name}`}/>
                    <strong className={articleTitle}>{node.frontmatter.title}</strong>
                </h2>

                <p className={description}>{node.frontmatter.excerpt}</p>

                {node.frontmatter.tag.split(',').map((tag: string) => {
                    return <span key={tag} className={chips}>{tag}</span>
                })}

                <span className={readingTime}>
                     <FontAwesomeIcon icon={faMugHot} size="1x"/>&nbsp;
                    lecture {Math.round(node.fields.readingTime.minutes)}m
                </span>

            </article>
        </Link>
    )
}

export default Card;